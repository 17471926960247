<template>
    <div class="page_support">
        <div class="wrapper">
            <div class="helpcenter-row">
                <support-ticket-form />
                <div class="support_ticket_sidebar">
                    <img
                        :src="require('Images/helpcenter-sidebar.svg')"
                        alt="help center"
                    >
                </div>
            </div>
        </div>
        <confirm-modal ref="confirm-modal" />
    </div>
</template>

<script>
import SupportTicketForm from '@/components/support/SupportTicketForm';
import ConfirmModal from '@/components/modals/ConfirmModal';

export default {
    components: {
        SupportTicketForm,
        ConfirmModal
    },
    props: {
        propOrderId: {
            type: String
        }
    },
    metaInfo: {
        title: 'Help Center'
    },
    computed: {
        apiContent() {
            return process.env.VUE_APP_API_CONTENT
        }
    }
}
</script>
